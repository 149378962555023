import './body.css';        
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';
import './body.css';
import VDVariation from '../buttons/vDVariation';


import React from 'react'
import { Link } from 'react-router-dom';

const EVDetailsBody = () => {
  return (
<section className='sec2-container h-20 p-5 justify-content-center' style={{height:'100%'}}>


<div className="row">  



      

        <div className='bg-fingerprint  row ' style={{marginLeft:'0%'}}>
      
<div className="col-11 row bg-dar justify-content-center " style={{gridGap:'40px'}}>

    <div className='col-md-5  col-lg-2 finger bg-white p-0' data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="0" style={{maxHeight:'40%',padding:'auto 2px'}}>  <h6 className='pt-3'>   Total Violence 
     Incidence </h6>  <h2 className="h1 EVNumber" style={{fontSize:'550%',height:'50%',}} data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="1">     122</h2>
     
     <div class=" text-white pt-2 my-4" style={{width:'400%',maxWidth:'100%',height:'40px',backgroundColor:'#0C5789'}}> View Details </div>
     


      </div>


<div className='col-md-5  col-lg-2 finger d-flex flex-column  bg-white p-0'

data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="0"


style={{maxHeight:'40%'}} ><h6 className='pt-1'>  Number of Electoral 
     Violence Victims </h6>  <h2 className="h1 EVNumber  " style={{fontSize:'540%',height:'50%'}} data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="1">     212</h2>
     <div class=" text-white pt-2 my-4" style={{width:'400%',maxWidth:'100%',height:'40px',backgroundColor:'#0C5789'}}> View Details </div>
      </div>


    <div className='col-md-12 col-lg-5  finger bg-white justify-content-center   row ' style={{maxHeight:'40%', height:'40%',paddingBottom:'20px'}} data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="2" >
      
  <h1 className='col-12'  style={{fontSize:'100%',fontStyle:'bold',display:'content',margin:'1%',boxSizing:'border-box'}}   >Category of Perpetrators </h1>

      <div className="col-md-4    blue-box p-2  " > 
      <div className='container d-flex  flex-row  ' style={{}}  ><h4 className='h6 text-white'>Security Agent   </h4> <img src="/images/Security Agent 1.svg" alt="" className='w-25 img-fluid' /> 
      </div> <h2 className='text-white ' >5%</h2> </div>


      <div className="col-md-4 p-2 yellow-box" > <div className='container d-flex  flex-row  justify-content-center' style={{paddingLeft:'1px',margin:'auto',boxSizing:'border-box'}} ><h4 className='h6  text-white'  style={{ paddingRight:'12px'}}>Party <br/>member   </h4> <img src="/images/Party member.svg" alt="" className='w-25'/> 
      </div> <h2 className='text-white' >5%</h2>  </div>
      <div className="col-md-3 p-2 blue-box "> <div className='container d-flex  flex-row '  ><h4 className='h6 p-1 text-white'>Party Agent   </h4> <img src="/images/Party Agent.svg" alt="" className='w-25' /> 
      </div> <h2 className='text-white'>5%</h2>  </div>
      <div className="col-md-4 p-2 blue-box"> <div className='container d-flex  flex-row justify-content-center'  ><h4 className='h6 p-1 text-white'>Party Thug  </h4> <img src="/images/Party Thugs.svg" alt="" className='w-25' /> 
      </div> <h2 className='text-white'>5%</h2>   </div>
      <div className="col-md-4 p-2 blue-box"> <div className='container d-flex  flex-row justify-content-center'  ><h4 className='h6 p-1  my-auto text-white'>Voter   </h4> <img src="/images/Voter.svg" alt="" className='w-25 p-1' /> 
      </div> <h2 className='text-white'>5%</h2>     </div>
      <div className="col-md-3 p-2 blue-box"> <div className='container d-flex  flex-row  justify-content-center'  ><h4 className='h6 p-1 my-auto text-white'>Others  </h4> <img src="/images/other.svg" alt="" className='w-25 ' /> 
      </div> <h2 className='text-white'>5%</h2>  </div>



     </div>

    <div className='col-12 col-xl-6 finger bg-white position-relative' data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="3"  style={{maxHeight:'50%',height:'50%'}}>
            <h4 style={{fontSize:'100%',fontStyle:'bold',display:'content',margin:'2%',boxSizing:'border-box'}}  >Gender of Electoral VIolence Victims</h4>

     <div className='container   d-flex  '>

<div className='d-flex   ' id='circleHolder'>


<div className="circle-1 d-flex "> 
<h4  className='text-light rotate  ' style={{height:'min-content',margin:'auto 10px'}}  > Female</h4>

<div className=' ' style={{height:'min-content',margin:'auto -47px'}}>  
<img src="/images/woman-female-person-avatar-user-31835.svg" alt="" srcset="" className='img-responsive' />
  </div>
  <h1 className='text-light overlap-percent ' style={{height:'min-content',margin:'auto 43px'}}>8%</h1>

 </div>

<div className="circle-2 d-flex flex-row" style={{padding:'0px'}}> 
<hr className='line'/>

<h4  className='text-light rotate Gender-Gender ' style={{}}  > Male</h4>

<div className='Gender-img-box' style={{}}>  
<img src="/images/male-icon-15.svg" alt="" srcset="" className='img-responsive' />
  </div>
  <h1 className='text-light text-figure ' style={{}}>25%</h1>

 </div>






</div>



<div className="bg-dark p-5 position-absolute  end-0 h-75" style={{width:'35%',opacity:'.2',zIndex:'1'}}></div>

<div className="container b d-flex unidentified-gender     " style={{width:'min-content',top:'180px'}}>

<h6 className="rotate" style={{marginLeft:'-40px'}}>Unidentified <br/>
Gender</h6>


<div className="Gender-img-box " style={{marginLeft:'-20px'}}>  
<img src="/images/member_1.svg" alt="" srcset="" className='img-responsive' style={{width:'70px'}}  />
</div>
<h2 style={{marginLeft:'-20px'}}> 68%</h2>
</div>







     </div>











     </div>
     
    {/* <div className='col-md-4 finger bg-white gx-2 gy-2  row' data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="4">

<h1  style={{fontSize:'100%',fontStyle:'bold'}}   >Category of Victims </h1>

      <div className="col-4  blue-box p-3 " style={{maxHeight:'100%',maxWidth:'100%'}}  > 
      <div className='container d-flex  flex-row '  ><h4 className='h6 text-white'>Security Agent   </h4> <img src="/images/Security Agent 1.svg" alt="" /> 
      </div> <h2 className='text-white'>5%</h2> </div>


      <div className="col-4 p-3 yellow-box"> <div className='container d-flex  flex-row '  ><h4 className='h6 p-1 text-white'>Party member   </h4> <img src="/images/Party member.svg" alt="" /> 
      </div> <h2 className='text-white'>5%</h2>  </div>
      <div className="col-4 p-3 blue-box "> <div className='container d-flex  flex-row '  ><h4 className='h6 p-1 text-white'>Party Agent   </h4> <img src="/images/Party Agent.svg" alt="" /> 
      </div> <h2 className='text-white'>5%</h2>  </div>
      <div className="col-4 p-3 blue-box"> <div className='container d-flex  flex-row '  ><h4 className='h6 p-2 text-white'>Party Thug  </h4> <img src="/images/Party Thugs.svg" alt="" /> 
      </div> <h2 className='text-white'>5%</h2>   </div>
      <div className="col-4 p-3 blue-box"> <div className='container d-flex  flex-row '  ><h4 className='h6 p-3 text-white'>Voter   </h4> <img src="/images/Voter.svg" alt="" /> 
      </div> <h2 className='text-white'>5%</h2>     </div>
      <div className="col-4 p-3 blue-box"> <div className='container d-flex  flex-row '  ><h4 className='h6 p-3 text-white'>Others  </h4> <img src="/images/other.svg" alt="" /> 
      </div> <h2 className='text-white'>5%</h2>  </div>





     </div> */}

<div className='col-md-12 col-lg-5  finger bg-white gx-2 gy-2 container-fluid position-relative row ' style={{maxHeight:'50%', height:'50%',paddingLeft:'40px',paddingBottom:'10px'}} data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="2" >
      
  <h1  style={{fontSize:'100%',fontStyle:'bold',display:'content',margin:'1%'}}   >Category of Victims </h1>

      <div className="col-md-4  blue-box p-2  "  style={{display:'conten'}} > 
      <div className='container d-flex  flex-row '  ><h4 className='h6 text-white'>Security Agent   </h4> <img src="/images/Security Agent 1.svg" alt="" className='w-25' /> 
      </div> <h2 className='text-white' >5%</h2> </div>


      <div className="col-md-4 p-2 yellow-box" > <div className='container d-flex  flex-row  justify-content-center' style={{paddingLeft:'1px',margin:'auto'}} ><h4 className='h6  text-white'  style={{ paddingRight:'12px'}}>Party <br/>member   </h4> <img src="/images/Party member.svg" alt="" className='w-25'/> 
      </div> <h2 className='text-white' >5%</h2>  </div>
      <div className="col-md-4 p-2 blue-box "> <div className='container d-flex  flex-row '  ><h4 className='h6 p-1 text-white'>Party Agent   </h4> <img src="/images/Party Agent.svg" alt="" className='w-25' /> 
      </div> <h2 className='text-white'>5%</h2>  </div>
      <div className="col-md-4 p-2 blue-box"> <div className='container d-flex  flex-row justify-content-center'  ><h4 className='h6 p-1 text-white'>Party Thug  </h4> <img src="/images/Party Thugs.svg" alt="" className='w-25' /> 
      </div> <h2 className='text-white'>5%</h2>   </div>
      <div className="col-md-4 p-2 blue-box"> <div className='container d-flex  flex-row justify-content-center'  ><h4 className='h6 p-1  my-auto text-white'>Voter   </h4> <img src="/images/Voter.svg" alt="" className='w-25 p-1' /> 
      </div> <h2 className='text-white'>5%</h2>     </div>
      <div className="col-md-4 p-2 blue-box"> <div className='container d-flex  flex-row  justify-content-center'  ><h4 className='h6  my-auto text-white'>Others  </h4> <img src="/images/other.svg" alt="" className='w-25
      
      
      p-2 ' /> 
      </div> <h2 className='text-white'>5%</h2>  </div>



     </div>



</div>

            <div  className='contianer pl-0 col-1  '  style={{maxWidth:'100%',margin:'auto'}}>
<BtnFingerprint  newClass="bg-danger m-auto justify-content-center  d-flex " url="/#body"/>

<BackButton  class=" bg-dark m-1" url="/Road_to_state/ViolenceTracking"/>


</div>


            </div>
            
            </div>

    
    </section>


    




  )
}

export default EVDetailsBody;