import { dialogClasses } from "@mui/material";
import React from "react";
import Style from './body.css';
import { Route } from "react-router-dom";
import { Link } from "react-router-dom";
class Body extends React.Component{
    render()
{
    return(

        <div className="sec2-container container bg-white" id="body">
            <div className="h-30 py-5"></div>
                <h1>Dashboard</h1>
                <hr className="hr m-auto " style={{width:'20%',borderWidth:'4px',color:'red'}} />
                <div className="finger_background container bg-dak pt-5 " >
                <div className="row gy-5 py-5  justify-content-center">

                            {/* Election History Data */}
                        <div className="card col-lg-5 my-3  mx-4 road-state " data-aos="fade-up" data-aos-delay="300" data-aos-offset="0" >
                            <Link  to="/PreElection" className="link" style={{color:'black',textDecoration:'none'}}>
                                <div className="card-title">  </div>

                                <img src="/images/Vector.svg" className="img-fluid  " style={{maxWidth:'100%',maxHeight:'100%',width:'100px',margin:'auto'}}  alt="" />
                                <div className="card-body h2 road-to-state-text " data-aos="fade-up" data-aos-delay="300" data-aos-offset="2" >
                                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum id iusto eum, vitae quos provident aperiam velit aut reprehenderit quibusdam numquam impedit odit quo praesentium excepturi incidunt consequuntur est exercitationem? */}
                                    Election History<br />In Figures      
                                    <hr className="hr button-hr" />
                                </div>

                            </Link> 
                        </div>

                            {/* General Election Data */}
                        <div className="card col-lg-5 my-3 road-presidency" data-aos="fade-up" data-aos-delay="300"
                               data-aos-offset="1">
                                <Link  to="/ElectionDay" className="link" style={{color:'black',textDecoration:'none'}}>
                                    <div className="card-title"></div>
                                        <img src="/images/Vector.svg" className="img-fluid  " style={{maxWidth:'100%',maxHeight:'100%',width:'100px',margin:'auto'}}  alt="" />

                                    <div className="card-body p- h2  road-to-state-text "  >
                                        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum id iusto eum, vitae quos provident aperiam velit aut reprehenderit quibusdam numquam impedit odit quo praesentium excepturi incidunt consequuntur est exercitationem? */}
                                            General Election <br />Statistics
                                            <hr className="hr button-hr" />
                                    </div>
                                </Link> 
                        </div>

                                {/* Off-Circle election data */}
                        <div className="card col-lg-5 my-3 road-presidency" data-aos="fade-up" data-aos-delay="300"
                            data-aos-offset="1">
                            <Link to="/offcycle_elections" className="link" style={{ color: 'black', textDecoration: 'none' }}>
                                <div className="card-title"></div>
                                <img src="/images/Vector.svg" className="img-fluid  " style={{ maxWidth: '100%', maxHeight: '100%', width: '100px', margin: 'auto' }} alt="" />

                                <div className="card-body p- h2  road-to-state-text "  >
                                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum id iusto eum, vitae quos provident aperiam velit aut reprehenderit quibusdam numquam impedit odit quo praesentium excepturi incidunt consequuntur est exercitationem? */}
                                    Off-Cycle Election <br/>Analysis
                                    <hr className="hr button-hr" />
                                </div>
                            </Link>
                        </div>

                    </div>
                    {/* <div className="button">
                    </div> */}
                </div>
        </div>      
    )
}
};

export default Body;