
import './App.css';
import NavBar from './components/NavBar/NavBar';
import './App.css';
import Hero from './components/hero/Hero';
import Body from './components/body/DashboardBody';
import Footer from './components/footer/footer';
import { BrowserRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Btn from './components/buttons/button';
import Home from './pages/Home';
import Routes from './components/Loutes';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {useEffect} from "react";


function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  
  return (
    <div className="App"     >
      

      
  
    
    
    <BrowserRouter>

    <NavBar/> 

     <Routes />



    <Footer/>
  
   

 




</BrowserRouter>



 </div>






  );
}

export default App;


