import './body.css';        
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';


import React from 'react'
import { Link } from 'react-router-dom';

const ViolenceTrackingBody = () => {
  return (
<section className='sec2-container h-20 p-5 justify-content-center' style={{height:'100%'}}>


<div className="row">  



      <h1 className='p-5 h1' style={{marginBottom:'0%'}}>Violence Tracking</h1>  </div>
    

        <div className='bg-fingerprint  row ' style={{marginLeft:'0%'}}>
        <div className="container col-10 " >
          
            
            
            












<div className='row gx-1 container  ' style={{marginLeft:'10%',paddingLeft:'-100%' }}>   



<Button name="Electoral
Violence
Incidence" color=' #0C5789' className=" col-md-4" btnClass=" " link="/Road_to_state/ViolenceTracking/EV" />



<Button name="Hotspot
Mapping" color='#8F0000'  className="col-md-4  "/>
<Button name="Security
Risk Analysis" color=' #0C5789'  className="col-md-4 "  />




</div>


            </div>  

            <div  className='contianer pl-0 col-2  '  style={{maxWidth:'100%',margin:'auto'}}>
<BtnFingerprint  newClass="bg-danger m-auto justify-content-center  d-flex  " url="/#body"/>

<BackButton  class=" bg-dark m-1" url="/Road_to_state"/>


</div>


            </div>

    
    </section>


    




  )
}

export default ViolenceTrackingBody;