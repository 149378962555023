
import React  from 'react';

import  './hero.css';
import fingerprint from './fingerprint.svg';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { HashLink as Link } from 'react-router-hash-link';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'


class Navbar extends React.Component 


{
    
  

    state ={mouseOver:false};

   
    render(){





        return(


            <section className="bg-white text-left "> 
          

            
<div className="head-bg">


<div className="container   py-5 " style={{backgroundColor:''}}>  

            

 {/* <h1 className="Election">
           

            </h1> */}



                





    <div className="  pt-5 px-5 justify-content-center d-flex" style={{}}>
            <div className="h1 d-md-block d-none" style={{width:'50%'}}>  </div>

        
            <div className=" d-flex flex-column "  > 
            
            
              <h1 className=" text-light text-justify text-md-start display-2 e-droom" style={{fontStyle:'normal',fontWeight:'normal'   }}  data-aos="fade-up"  > Nigeria Election <br/> Data Dashboard</h1>
             
<p className="text-light  d-sm-flex tangline h4"style={{ fontWeight:'lighter' }} data-aos="fade-up"    data-aos-easing="ease-in-back" data-aos-duration="1000"  data-aos-easing="ease-in-back"
     >...your election data hub</p>



<Link  to ="#body" className='explore justify-content-center btn d-flex w-sm-100  m-auto ms-md-1' type="button" style={{}} smooth data-aos="fade-up" >

<img src={fingerprint} alt=""  className="img-fluid pt-2" onClick={this.HandleClick}/>
<h1 className='pt-2' style={{fontSize:'28px'}} >Explore</h1> 


</Link>

</div>
           
            </div>
            


            </div>
           



         </div>


            </section>
         
            
        );



    }




}

export default Navbar

