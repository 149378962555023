import React from 'react'
import './body.css';        
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';
import './body.css';
import VDVariation from '../buttons/vDVariation';
const ElectionDayBody = () => {
  return (
    

/* <iframe style={{border: '1px solid rgba(0, 0, 0, 0.1)'}}  width="1100" height="630" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNzWu6xoioi9aXkDyHzD9k2%2FELECTION-DATA-ROOM%3Fpage-id%3D0%253A1%26node-id%3D36-6496%26viewport%3D-18497%252C5601%252C0.66%26scaling%3Dscale-down-width%26starting-point-node-id%3D36%253A6496 %26hide-ui%3D1" allowfullscreen></iframe>
<div className=' d-flex justify-content-center'>
 */


<section className='sec2-container h-20 p-5 justify-content-center' style={{height:'100%'}}>
      

        <div className='bg-fingerprint   row ' style={{}}>
        <div className="container col-10 p-0 m-0  " >
            
            
            












<div className='row d-flex justify-content-center container  NavELection' style={{paddingLeft:'' }}>   

<h1 className='p-2' style={{marginBottom:'0%'}}  data-aos="fade-left" data-aos-delay="300"
     data-aos-offset="1" >Election Day</h1>

<Button name="Governorship 
Election" color=' #980000' className=" col-md-4 h2  d-flex justify-content-sm-center m-0 " btnClass=" " link="/ELectionDay/Governorship "  />



<Button name="Presidential Election" color=' #0C5789'  className="col-md-4 d-flex h2 justify-content-sm-center  m-0 " link="/ELectionDay/Presidential " />





</div>


            </div>  

            <div  className='contianer pl-0 col-2  '  style={{maxWidth:'100%',margin:'auto'}}>
<BtnFingerprint  newClass="bg-danger m-auto justify-content-center  d-flex  " url="/#body"/>

<BackButton  class=" bg-dark m-0 mt-1" url="/#body"/>


</div>


            </div>


    </section>



   




  )
}

export default ElectionDayBody