import React from 'react'
import './body.css';        
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';
import './body.css';
import VDVariation from '../buttons/vDVariation';
const GovernorshipBody = () => {
  return (
    

/* 
 */

<div className=' d-flex justify-content-center '     > 
<iframe style={{border: '1px solid rgba(0, 0, 0, 0.1)'}} className='iframe'   src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNzWu6xoioi9aXkDyHzD9k2%2FELECTION-DATA-ROOM%3Fpage-id%3D817%253A189%26node-id%3D1059-1499%26viewport%3D6534%252C2022%252C0.25%26scaling%3Dscale-down-width%26starting-point-node-id%3D1059%253A1499 %26hide-ui%3D1" allowfullscreen></iframe>

<BackButton  class=" bg-dark m-1" url="/ElectionDay"/>
</div>




   




  )
}

export default GovernorshipBody