import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import RoadToState from "../pages/Road_to_state";
import ViolenceTracking from "../pages/ViolenceTracking";
import EV from "../pages/EV";
import EVDetails from "../pages/EVDetails"
import PreElection from "../pages/PreElection";
import ElectionDay from "../pages/ElectionDay";
import Governorship from "../pages/Governorship";
import Presidential from "../pages/Presidential";
import OffCircle from "../pages/OffCycle";

const Loutes = () => {
  return (
    

    <Routes>

        <Route  exact path="/"  Component={Home}  />
        <Route  exact path="/Road_to_state"  Component={RoadToState}  />
        <Route  exact path="/Road_to_state/ViolenceTracking"  Component={ViolenceTracking}  />
        <Route  exact path="/Road_to_state/ViolenceTracking/EV"  Component={EV}  />
        <Route  exact path="/Road_to_state/ViolenceTracking/EVDetails"  Component={EVDetails}  />
          <Route exact path="/PreElection" Component={PreElection} />

          <Route exact path="/offcycle_elections" Component={OffCircle} />

        <Route  exact path="/ElectionDay"  Component={ElectionDay}  />

    <Route  exact path="/ElectionDay/Governorship"  Component={Governorship}  />
    <Route  exact path="/ElectionDay/Presidential"  Component={Presidential}  />
    
    {/* <Route  exact path="www.kdi.org.ng"  /> */}
    </Routes>






  )
}

export default Loutes;

