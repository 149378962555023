import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';


 const button = (props) => {
    const name=props.name;
    const color=props.color;
    const newclass=props.className;
    const _class="container " +" " + newclass;
    const border="3px solid"+ " "+color;
    const newBtnClass=props.btnClass;
    const btn_class=" card p-2 btn-finger  " + "" +newBtnClass;
    const link=props.link;
  
    return (
   
        <div className={_class} style={{display:'flex',justifyContent:'center'}}>   
    

        <div className='p-3  '   style={{width:'250px',border:border,filter:' drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.25))',
        borderRadius: '27px', maxHeight:'100%',margin: '10px 10px', width:'100%'  }} data-aos="fade-up" data-aos-delay="300"
        data-aos-offset="1"  data-aos-duration="500"  data-aos-easing="ease-in-back">   

    <button className={btn_class}    style={{backgroundColor:color,borderRadius: '27px',margin:'auto', color:'white',maxHeight:'100%',height:'180px',width:'100%'}} >

        <Link to={link} style={{color:'white',textDecoration:'none',fontSize:'60%',margin:'auto'}}  data-aos="fade-up" data-aos-delay="300"
        data-aos-offset="1"  data-aos-duration="700"  data-aos-easing="ease-in-back" >
  
        {name}
        </Link>

    </button>


    </div>

    </div>
    





  )
}

export default button;