import React from 'react'
import GovernorshipBody from '../components/body/GovernorshipBody'

const PreElection = () => {
  return (


    


    <GovernorshipBody />







    





  )
}

export default PreElection