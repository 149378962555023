import './body.css';        
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';
import './body.css';
import VDVariation from '../buttons/vDVariation';


import React from 'react'
import { Link } from 'react-router-dom';

const EVBody = () => {
  return (
<section className='sec2-container h-20 p-5 justify-content-center' style={{height:'100%'}}>


<div className="row">  



      <h1 className='p-3 h4' style={{marginBottom:'0%'}}>Cases of Violence As At</h1>  </div>
        <div className="bg-dark w-25 m-auto h-auto">

          <p className="h5 p-3 text-light">from Db</p>

        </div>

        <div className='bg-fingerprint  row ' style={{marginLeft:'0%'}}>
        <div className="container col-10" >
          
            
            
            












<div className='row gx-1 container  ' style={{marginLeft:'10%',paddingLeft:'-100%' }}>   




<h2 className="h1 EVNumber" style={{fontSize:'150px'}} data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="1">     122</h2>

<VDVariation name="View Details" color='#0C5789'  className="" link='/Road_to_state/ViolenceTracking/EVDetails' />



</div>


            </div>  

            <div  className='contianer pl-0 col-2  '  style={{maxWidth:'100%',margin:'auto'}}>
<BtnFingerprint  newClass="bg-danger m-auto justify-content-center  d-flex   " url="/#body"/>

<BackButton  class=" bg-dark m-1" url="/Road_to_state/ViolenceTracking"/>


</div>


            </div>

    
    </section>


    




  )
}

export default EVBody;