
 export const MenuBar=[




 { title:'Home',
    url:'/',
     cName:'nav-link',
     hover:'nav-hover',
     id:'home'




},
 {   title:'About',
 url:'#',
cName:'nav-link',
hover:'nav-hover',
id:'x'




 },
{   title:'Dashboard',
 url:'/',
cName:'nav-link',
hover:'nav-hover',
id:'x'




 }












 ]

