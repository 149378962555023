
import EVDetailsBody from '../components/body/EVDetailsBody';


import React from 'react'

const EV = () => {
  return (
    


      <div>

   
 
<EVDetailsBody />

    


      </div>


  )
}

export default EV;