import React from 'react';
import { Link } from 'react-router-dom';
import  './btnFingerprint';











const btnFingerprint =(props) => {

  const newClass=props.newClass;
  const url=props.url;
  


  
 
  


  return (
    <div>


      
<button className={newClass} id="btn-finger" style={{maxWidth:'40px',paddingTop:'5px',maxHeight:'80px',backgroundColor:'red',border:'none'}} data-aos="fade-up" data-aos-delay="300"
     data-aos-offset="2">
    <Link to={url} smooth >
<img src="/images/fingerprint.svg" alt="" srcset=""  className='img-responsive '/>
</Link>

</button>



    </div>
    

  )
}

export default btnFingerprint;








