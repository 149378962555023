import React from 'react'
import PresidentialBody from '../components/body/PresidentialBody'

const Presidential = () => {
  return (


    


    <PresidentialBody />







    





  )
}

export default Presidential