



import React from "react";


import { Link } from 'react-router-dom';


import './button.css';


const BackButton = (props) => {

  const _class= props.class;
  const url= props.url;

  return (
    <button id="back"  className={_class} style={{border:'none', maxHeight:'80px',maxWidth:'40px'}} data-aos="fade-up" data-aos-delay="300"
    data-aos-offset="1">

<Link to={url}>
    <img src="/images/back_button.svg" alt="" className="img-responsive"/>

    </Link>

</button>
  )
}

export default BackButton;

