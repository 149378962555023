import React from 'react'
import './body.css';
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';
import './body.css';
import VDVariation from '../buttons/vDVariation';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const OffCircleElection = () => {
    return (
        <div>
            <div className='offcircle-election-header'>Imo, Kogi and Bayelsa Governorship Election 2023</div>
            <div className='viz-container bg-success d-flex justify-content-center'>
                <iframe className='h-100' title="Report Section" width="100%" height="100vh" src="https://app.powerbi.com/view?r=eyJrIjoiM2VmNWEyNDctZTkxZi00NjgwLWI2NjktNjc0YjgxNzNiZTYzIiwidCI6IjJiNjZhNzNkLTk3OTUtNDU0Ni05MjJjLTBlMDY2MmFiYTI4MyJ9&pageName=ReportSectiona27f3e3a51e65c57be90"></iframe>
                <BackButton class=" bg-dark m-1 position-sticky" url="/#body" />
            </div>
        </div>
    )
}

export default OffCircleElection