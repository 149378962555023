import './footer.css';
import React  from 'react';
import { render } from '@testing-library/react';
import { dividerClasses } from '@mui/material';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import Css from './footer.css';



class Footer extends React.Component{



render()
{






    return(


        <section className="footer bg-dark p-2 h-10">

        <footer className='container '>
        <div className="row d-flex justify-content-center ">

        <div className="col-lg-3  mt-5  d-flex flex-column  ">   
        
               <h1 className='text-light col text-start h6'>Like our <br/> facebook page</h1>
        <hr className='' style={{color:'white',width:'13%'}} />     
        
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fkdinigeria%2Fposts%2Fpfbid036j1nKMQyLQYJsVw6ki84Pw2Njgk9Ak5wj5CuuszbXf38kkGeeSNPDv7iEPMeB7WYl&show_text=true&width=500" width="100%" height="100%" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
         </div>
        
         <div className="col-lg-3 mt-5  d-flex  flex-column mt-2"  >   
        
        
        <h1 className='text-light col text-start h6'>Follow us <br/> on twitter</h1>
        <hr className='' style={{color:'white',width:'13%'}} />   
         <div className='' style={{width:'100%', height:'180px',maxHeight:'100%',overflow:'hidden'}}>  
         <a href=" https://twitter.com/KDI_ng?s=20" className='d-flex'>   
        
          <img src="/images/twitter.jpg" className="" style={{width:'100%'}} alt="" />
        
        
         </a> 

         {/* <TwitterTimelineEmbed
  sourceType="profile"
  screenName="saurabhnemade"
  options={{height: 160}}
/> */}

          {/* <TwitterTweetEmbed
  tweetId={'1621480409233063939'} options={{   }}  
/>
         
           <TwitterFollowButton
    screenName={'KDI_ng'}
  />  */}
            
            </div>  
             </div>  
             <div className="col-lg-3  mt-5" style={{display:'flex',maxWidth:'100%',overflow:'hidden'}}>

            <form action=""  className='  text-start ' style={{width:'100%'}}>   
            <label className='text-light h6  ' style={{width:'100%'}} >Email Newsletter</label>
            <div className="form-group  d-flex  ">
              
              <input type="text" className='input input-group-text     '  style={{width:'70%'}} placeholder='Your Email'/>
              <input type="submit" className='btn btn-submit btn-danger  ' style={{marginLeft:'5px',fontSize:'10px',width:'30%'}} name="Sign Up" id="" />
              
              
              </div>
              </form>
              

             </div>
             <div className="col-lg-3 mt-5">

             <h1 className='text-light col text-start h6'>Locate Us</h1>
        <hr className='' style={{color:'white',width:'100%'}} />  
  <p className='text-light text-start'>
    No 53 A.N. Amosu Avenue,
    NAF Valley Estate,Behind Abacha Barracks,Asokoro, FCT, Abuja, Nigeria.
    
    </p>
    <hr className='' style={{color:'white',width:'13%'}} /> 
  <p className='text-light text-start '>
    1 Aresa Close, Gbodofon Area,
    Behind Union Bank, Aregbe, Osogbo , Osun State.
    </p>
    <hr className='' style={{color:'white',width:'13%'}} />  
  <p className='text-light text-start' >Email: info@kimpact.org.ng</p>
  <p className='text-light text-start'>Phone: +2348103947690,+2349020118336</p>
 </div>
 <hr className='m-5' style={{color:'white',width:'100%'}} /> 

        <div className="col-12 text-light text-start'">Copyright 2023 KDI- All Rights Reserved</div>
        
        </div>
        

        </footer>


        </section>
        




    );
}



}

export default Footer;