import Navbar from "../components/NavBar/NavBar";
import Footer from "../components/footer/footer";
import RoadToStateBody from "../components/body/RoadToStateBody";



import React from 'react'

const Road_to_state = () => {
  return (
    


      <div>


    <RoadToStateBody/>


    


      </div>


  )
}

export default Road_to_state