

import ViolenceTrackingBody from "../components/body/ViolenceTrackingBody";



import React from 'react'

const ViolenceTracking = () => {
  return (
    


      <div>

    <ViolenceTrackingBody/>
 


    


      </div>


  )
}

export default ViolenceTracking;