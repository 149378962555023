import React from 'react'
import PreELectionBody from '../components/body/PreElectionBody'

const PreElection = () => {
  return (


    


    <PreELectionBody />







    





  )
}

export default PreElection