import React, {useEffect} from 'react'
import OffCircleElection from '../components/body/OffCycleElection'

const OffCircle = () => {
    useEffect(()=>{
        console.log("Done initializing the OffCycle components");
        const biContainer = document.getElementById("bicontainer");
        console.log(biContainer);

        // insert the code for mobile responsiveness here

    }, []);

    return (
        <OffCircleElection />
    )
}
export default OffCircle