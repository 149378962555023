import React from 'react'
import './body.css';        
import Button from '../buttons/button';
import BackButton from '../buttons/BackButton';
import BtnFingerprint from '../buttons/btnFingerprint';
import './body.css';
import VDVariation from '../buttons/vDVariation';
const PreElectionBody = () => {
  return (
    <div className='bg-success d-flex justify-content-center'>

<iframe style={{border: '1px solid rgba(0, 0, 0, 0.1)'}} className='iframe'   src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FY2yeXtSCJu8mMhsXbeQZp7%2FKIMPACT-MAGIC-SCREEN%3Fpage-id%3D0%253A1%26node-id%3D1494-21329%26viewport%3D2621%252C-733%252C0.09%26scaling%3Dscale-down-width%26starting-point-node-id%3D1494%253A21329 %26hide-ui%3D1" allowfullscreen></iframe>


<BackButton  class=" bg-dark m-1 position-sticky" url="/#body" />
    
    </div>




  )
}

export default PreElectionBody