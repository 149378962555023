import './button.css';


import { Link } from 'react-router-dom';

import React from 'react'

const vDVariation = (props) => {

    const name=props.name;
    const color=props.color;
    const newclass=props.className;
    const _class="container " +" " + newclass;
    const border="3px solid"+ " "+color;
    const newBtnClass=props.btnClass;
    const btn_class="  position-absolute btn-viewD " + "" +newBtnClass;
    const link=props.link;

  return (
    
    
    <div className={_class} style={{display:'flex',justifyContent:'center'}}>   
    

        <div className='p-4 position-relative  '   style={{width:'255px',border:border,filter:' drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.25))',
        borderRadius: '27px', maxHeight:'70%',maxWidth:'200px',margin: '10px 10px',height:'20px'}}  data-aos="flip-left" data-aos-delay="600"
        data-aos-offset="3"data-aos-duration="500"  data-aos-easing="ease-in-back" >   

    <button className={btn_class}    style={{backgroundColor:color,borderRadius: '100px', color:'white',maxHeight:'80%',height:'80px',left:'20px',top:'7px',width:'150px',border:'none' }}>

        <Link to={link} style={{color:'white',textDecoration:'none',marginTop:'-30%',fontSize:'15px'}} >

        {name}
        </Link>

    </button>


    </div>

    </div>
    




  )
}

export default vDVariation;