
import Hero from '../components/hero/Hero';
import Body from '../components/body/DashboardBody';
import React from 'react'
import { dividerClasses } from '@mui/material';
import btnFingerprint from '../components/buttons/btnFingerprint';

const Home = () => {
  return (

    <div>


      
    <Hero/> 

 
    <Body/> 
   

    </div>
  )
}

export default Home