import './body.css';        
import Button from '../buttons/button';
import BtnFingerprint from '../buttons/btnFingerprint';
import BackButton from '../buttons/BackButton';



import React from 'react'
import { Link } from 'react-router-dom';

const RoadToStateBody = () => {
  return (
    
    <section className='sec2-container h-20 p-5 justify-content-center' style={{height:'100%'}}>
      <h1 className='p-5' style={{marginBottom:'0%'}}  data-aos="fade-left" data-aos-delay="300"
     data-aos-offset="1" >Road to State House</h1>

        <div className='bg-fingerprint  row ' style={{marginLeft:'0%'}}>
        <div className="container col-10 " >
            
            
            












<div className='row gx-1 container  ' style={{marginLeft:'10%',paddingLeft:'-100%' }}>   



<Button name="Violence Tracking" color=' #980000' className=" col-md-4" btnClass=" " link="/Road_to_state/ViolenceTracking"  />



<Button name="Election Process and Procedures" color=' #0C5789'  className="col-md-4  "/>
<Button name="Result Tracking" color=' #6D6862'  className="col-md-4 "  />




</div>


            </div>  

            <div  className='contianer pl-0 col-2  '  style={{maxWidth:'100%',margin:'auto'}}>
<BtnFingerprint  newClass="bg-danger m-auto justify-content-center  d-flex  " url="/#body"/>

<BackButton  class=" bg-dark m-1" url="/#body"/>


</div>


            </div>


    </section>

    




  )
}

export default RoadToStateBody;