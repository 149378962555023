
import React  from 'react';
import { MenuBar } from './MenuBar';
 import './NavBar.css';
 import SearchBar from './SearchBar';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/js/src/collapse.js";
import { Link, Route } from 'react-router-dom';
import "/node_modules/jquery/dist/jquery.min.js";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';






class NavBar extends React.Component 


{
    state ={mouseOver:false};

    // handleOver=()=>
    // {

       
    //     this.setState({mouseOver:!this.state.mouseOver});


        

    // }
    // const handleClick = () => {
    //     window.location.replace('https://bobbyhadz.com');
    //   };

    render(){





        return(

           
//            <nav className="navbar navbar-expand-lg  bg-light   ">

           
         
//             <div className="container">
                

//             <div className="navbar-logo nav-logo navbar-brand py-2" >
            
//                 </div>


//                 <button className="navbar-toggler"
                
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#navmenu">


//             <span className="navbar-toggler-icon"></span>
//                 </button>
            

//                 <div className="collapse navbar-collapse " id="navmenu">

//                 <ul className="navbar-nav ms-auto">

//                     <div className="input-group d-none">

//                     <input type="text" className="form-control input-search"  />
//                     <div class="input-group-append">
//       <button class="btn btn-secondary" type="button" >
      
//       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
//   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
// </svg>
//       </button>
//     </div>
//                     </div>
              
            
//         {MenuBar.map((item,index) =>  
        
        
//     {

//         return(
//         <li  key={index} className="nav-item">  
        
//         <Link to={item.url} className={item.cName} onMouseOver={this.handleOver} >

//             {item.title}
//         </Link>
       
//         </li>
     
        

// )


//     }
        
//         )}

       
//    </ul>




//                 </div>

    
       

          
           

           


            

//             </div>
                
              
              


              
//             </nav>
<Navbar bg="light" expand="lg">
<Container>
<Navbar.Brand href="#home"><div className="navbar-logo nav-logo navbar-brand " >
            
                         </div></Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse id="basic-navbar-nav">
  <Nav className="me-auto Collapse">
    
           


    {/* <Nav.Link href="#home">Home</Nav.Link>
    <Nav.Link href="#link">Link</Nav.Link> */}
    {MenuBar.map((item,index) =>  
        
        
         {
        
             return(
                <li  key={index} className="nav-item li-Element">  
                
                <Link to={item.url} id={item.id} className={item.cName} onMouseOver={this.handleOver} >
        
                    {item.title}
                </Link>
               
                </li>
             
                
        
        )
        
        
            }
                
                )}



  </Nav>
</Navbar.Collapse>
</Container>
</Navbar>


        );



    }




}

export default NavBar




function home()
{

    if(document.getElementById=="home")
    {
        window.location.href = 'http://www.kdi.org.ng';

        window.alert("hi");
        console.log("hi")

    }



}