import React from 'react'
import ElectionDayBody from '../components/body/ElectionDay'

const PreElection = () => {
  return (


    


    <ElectionDayBody />







    





  )
}

export default PreElection