
import EVBody from '../components/body/EVBody';


import React from 'react'

const EV = () => {
  return (
    


      <div>

   
 
<EVBody/>

    


      </div>


  )
}

export default EV;